import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { apiGetFullText } from './companyAPI';

export interface BodyState {
  // Store fileTexts as a map of filename to fullText
  fileTexts: {filename: string, fullText: string}[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BodyState = {
    fileTexts: [],
    status: 'idle',
};

export const getFileTexts = createAsyncThunk(
  'company/getFileTexts',
  async ({company, filename}: {company: string, filename: string}) => {
    const response = await apiGetFullText(company, filename);
    let ret = {
        filename: filename,
        fullText: response?.data || ""
    }
    return ret;
  }
);

export const companySlice = createSlice({
  name: 'company',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getFileTexts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getFileTexts.fulfilled, (state, action) => {
        state.status = 'idle';
        let index = state.fileTexts.findIndex((fileText) => fileText.filename === action.payload.filename);
        if (index !== -1) {
            state.fileTexts[index] = action.payload;
        } else {
          state.fileTexts.push(action.payload);
        }
      })
      .addCase(getFileTexts.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const fileTexts = (state: RootState) => state.company.fileTexts;

export default companySlice.reducer;
