import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { apiGetAllTexts } from './companyProfileAPI';

export interface BodyState {
  allTexts: {filename: string, text: string}[];
  status: 'idle' | 'loading' | 'failed';
}

const initialState: BodyState = {
    allTexts: [],
    status: 'idle',
};

export const getAllTexts = createAsyncThunk(
  'companyProfile/getAllTexts',
  async (company: string) => {
    const response = await apiGetAllTexts(company);
    return response?.data || {};
  }
);

export const companyProfileSlice = createSlice({
  name: 'companyProfile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getAllTexts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllTexts.fulfilled, (state, action) => {
        state.status = 'idle';
        let allTexts: {filename: string, text: string}[] = [];
        let mp = action.payload;
        for (let filename in mp) {
            allTexts.push({filename: filename, text: mp[filename]});
        }
        state.allTexts = allTexts;
      })
      .addCase(getAllTexts.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const fullText = (state: RootState) => state.companyProfile.allTexts;

export default companyProfileSlice.reducer;
