import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../app/store';
import { apiGetCompanies } from './filterAPI';

export interface FilterState {
  companyOptions: string[];
  selectedCompanies: string[];
  summarize: boolean;
  pdfView: boolean;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: FilterState = {
  companyOptions: [],
  selectedCompanies: [],
  summarize: false,
  pdfView: true,
  status: 'idle',
};

export const getCompanies = createAsyncThunk(
  'filter/getCompanies',
  async () => {
    const response = await apiGetCompanies();
    return response?.data || [];
  }
);

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Define a reducer for the selected companies
    changeSelectedCompanies: (state, action: PayloadAction<string[]>) => {
      state.selectedCompanies = action.payload;
    },
    changeSummarize: (state, action: PayloadAction<boolean>) => {
      console.log("summarize", action.payload)
      state.summarize = action.payload;
    },
    changePdfView: (state, action: PayloadAction<boolean>) => {
      console.log("pdfView", action.payload)
      state.pdfView = action.payload;
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.status = 'idle';
        state.companyOptions = action.payload;
      })
      .addCase(getCompanies.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const { changeSelectedCompanies, changeSummarize, changePdfView } = filterSlice.actions;
export const companyOptions = (state: RootState) => state.filter.companyOptions;
export const selectedCompanies = (state: RootState) => state.filter.selectedCompanies;
export const selectSummarize = (state: RootState) => state.filter.summarize;
export const selectPdfView = (state: RootState) => state.filter.pdfView;

export default filterSlice.reducer;
